<template>
  <div id="Terra">
    <div class="header">
      <div class="left">
        <div class="SelectBox">
          <Select v-model="TerraTaskSelect">
            <Option value="0">全部</Option>
            <Option value="2">正射</Option>
            <Option value="1">模型</Option>
          </Select>
        </div>
        <div class="InputBox">
          <Input search
                 v-model="TerraTaskNameSelect"
                 @on-search="getResourceData"
                 placeholder="搜索模型名称" />
        </div>
      </div>
      <div class="ButtonBox">
        <Button type="info"
                style="margin-right: 10px"
                @click="TerraListControl"
                v-if="userInfo.type !== 0">传输列表</Button>
        <Button type="primary"
                @click="upLoadingModal = !upLoadingModal"
                v-if="userInfo.type !== 0">上传模型</Button>
      </div>
    </div>
    <div class="content">
      <div class="monthItem"
           v-for="(item1, idx) in monthData"
           :key="idx">
        <h1>{{ item1.year_month }}</h1>
        <div class="TaskBox">
          <div class="TaskItem"
               v-for="(item2, index) in item1.resource_list"
               :key="index"
               @click="lookLook(item2)">
            <div class="ItemBox">
              <div class="imgBox">
                <img :src="icon.img"
                     alt="" />
              </div>
              <div class="text">
                <div class="textLeft">
                  <h2>{{ item2.mapname }}</h2>
                  <h2>{{ item2.create_time }}</h2>
                </div>
              </div>
              <div class="label">
                <span>{{ item2.type == 1 ? "模型" : "正射" }}</span>
              </div>
            </div>
            <!--<i class="ivu-icon ivu-icon-md-more"></i>-->
            <div class="more"
                 @click.stop>
              <Poptip placement="right-start">
                <i class="more_i ivu-icon ivu-icon-md-more"></i>
                <div class="api"
                     slot="content">
                  <div class="Button"
                       @click="alterModelFn(item2)">
                    <Icon type="md-create"></Icon>
                    重命名
                  </div>
                  <div class="Divider"></div>
                  <div class="Button"
                       @click="deleteTask(item2)">
                    <Icon type="md-trash"></Icon>
                    删除
                  </div>
                </div>
              </Poptip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="TerraList"
         :style="style">
      <TerraTaskList :OSSdata="OSSdata" />
    </div>
    <!--弹窗-->
    <Modal width="400"
           v-model="upLoadingModal"
           class-name="Terra_upLoading-modal">
      <p slot="header">
        <span>上传模式</span>
      </p>
      <div style="width: 100%; height: 100%">
        <div class="content">
          <div class="task"
               :style="upLoadingStatus == 1 ? LoadingStyle_pr : LoadingStyle"
               @click="upLoadingStatus = 1">
            <img :src="
                upLoadingStatus == 1 ? icon.build_cloud_pr : icon.build_cloud
              "
                 alt="" />
            <span :style="upLoadingStatus == 1 ? 'color:#fff' : 'color:#2196F3'">在线生成</span>
          </div>
          <div class="upLoading"
               :style="upLoadingStatus == 2 ? LoadingStyle_pr : LoadingStyle"
               @click="upLoadingStatus = 2">
            <img :src="
                upLoadingStatus == 2 ? icon.build_model_pr : icon.build_model
              "
                 alt="" />
            <span :style="upLoadingStatus == 2 ? 'color:#fff' : 'color:#2196F3'">已有模型</span>
          </div>
        </div>
      </div>
      <div slot="footer">
        <Button type="primary"
                size="large"
                long
                @click="upLoading">继续</Button>
      </div>
    </Modal>

    <!--查看-->
    <Modal v-model="lookModel"
           fullscreen
           footer-hide
           class-name="Look-center-modal"
           @on-cancel="closeLookModel">
      <p slot="header">
        <span>{{ lookModelData.mapname }}</span>
      </p>
      <div style="width: 100%; height: 100%">
        <div class="OrthophotoContent"
             id="OrthophotoContent"
             v-if="lookModelData.type == 2"></div>
        <div class="ModelContent"
             id="ModelContent"
             v-if="lookModelData.type == 1"></div>
      </div>
    </Modal>
    <!--修改-->
    <Modal v-model="alterModel"
           width="360"
           ref="formValidate"
           @on-ok="alterName(alterModelData)"
           @on-cancel="formRight.name = ''">
      <p slot="header">
        <span>修改名称</span>
      </p>
      <div style="width: 100%; height: 100%; padding: 15px 10px 0px">
        <Form :model="formRight"
              label-position="right"
              :label-width="70">
          <FormItem label="新名称  :">
            <Input v-model="formRight.name"></Input>
          </FormItem>
        </Form>
      </div>
    </Modal>
  </div>
</template>

<script>
import Api from '@/utils/api.js';
import OSS from 'ali-oss';

import TerraTaskList from './TerraTaskList.vue';

import img from '@/assets/img/build_no_image.png';
import build_cloud from '@/assets/img/statistics/build_cloud.png';
import build_cloud_pr from '@/assets/img/statistics/build_cloud_pr.png';
import build_model from '@/assets/img/statistics/build_model.png';
import build_model_pr from '@/assets/img/statistics/build_model_pr.png';

export default {
  components: {
    TerraTaskList,
  },
  computed: {
    userInfo() {
      return JSON.parse(window.sessionStorage.getItem('user_info') || '{}');
    },
  },
  data() {
    return {
      icon: {
        img,
        build_cloud,
        build_cloud_pr,
        build_model,
        build_model_pr,
      },
      OSSdata: {}, //oss数据
      client: null, //oss对象
      cityList: [
        {
          value: '全部',
          label: '全部',
        },
        {
          value: '其他',
          label: '其他',
        },
      ],
      TerraTaskSelect: '0',
      TerraTaskNameSelect: '',
      TerraListShou: false,
      style: {
        right: '-640px',
      },
      upLoadingModal: false,
      upLoadingStatus: 1, //1,云计算  2,上传已有模型
      LoadingStyle: {
        backgroundColor: '#fff',
        border: '1px solid #DEDEDE',
      },
      LoadingStyle_pr: {
        backgroundColor: '#4C98FF',
        border: '1px solid #4C98FF',
      },
      monthData: [],
      //查看
      lookModel: false,
      lookModelData: {},
      map: null,
      GetMapDetail: null,
      imageLayerList: [],
      googleLayer: null,
      alterModel: false,
      alterModelData: null,
      formRight: {
        name: '',
      },
    };
  },
  watch: {
    TerraTaskSelect(val) {
      this.getResourceData();
    },
  },
  mounted() {
    var _this = this;
    this.getResourceData();
  },
  methods: {
    // 任务进度列表显示隐藏
    TerraListControl() {
      this.TerraListShou = !this.TerraListShou;
      if (this.TerraListShou) {
        this.style.right = '0';
      } else {
        this.style.right = '-640px';
      }
    },
    //上传方式确认
    upLoading() {
      this.$router.push({
        path: '/resourceIndex/establish',
        query: {
          Status: this.upLoadingStatus,
        },
      });
    },
    //获取资源列表
    getResourceData() {
      this.$Java_post('/resource/loadResource', {
        pm_first_id: this.userInfo.pm_id,
        type: this.TerraTaskSelect, //0全部 1模型 2正射
        page: 1,
        bf_name: this.TerraTaskNameSelect,
        limit: 10,
      }).then((res) => {
        console.log('获取资源列表', res);
        if (res.code == 1) {
          // year_month
          this.monthData = res.data.rows;
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },
    alterModelFn(item) {
      console.log(item);
      this.alterModel = true;
      this.alterModelData = item;
      this.formRight.name = item.mapname;
    },
    //修改名称
    alterName(item) {
      if (item.type == 1) {
        //模型
        this.$Java_post('/resource/model/delete', {
          model_id: item.id,
          pm_first_id: this.userInfo.pm_id,
          modelname: this.formRight.name,
        }).then((res) => {
          console.log('删除模型', res);
          if (res.code == 1) {
            this.getResourceData();
          } else {
            this.$Message.error(res.msg_customer);
          }
        });
      } else {
        //正射
        this.$Java_post('/resource/map/update', {
          ortho_id: item.id,
          pm_first_id: this.userInfo.pm_id,
          mapname: this.formRight.name,
        }).then((res) => {
          console.log('修改正射地图名称', res);
          if (res.code == 1) {
            this.getResourceData();
          } else {
            this.$Message.error(res.msg_customer);
          }
        });
      }
    },
    //删除
    deleteTask(item) {
      // console.log(item);
      if (item.type == 1) {
        //模型
        this.$Java_post('/resource/model/delete', {
          pm_first_id: this.userInfo.pm_id,
          model_id: item.id,
        }).then((res) => {
          console.log('删除模型', res);
          if (res.code == 1) {
            this.getResourceData();
          } else {
            this.$Message.error(res.msg_customer);
          }
        });
      } else {
        //正射
        this.$Java_post('/resource/map/delete', {
          map_id: item.id,
        }).then((res) => {
          console.log('删除正射', res);
          if (res.code == 1) {
            this.getResourceData();
          } else {
            this.$Message.error(res.msg_customer);
          }
        });
      }
    },
    //查看
    lookLook(item) {
      console.log(item);
      if (item.type == 2) {
        console.log('正射');
        sessionStorage.setItem('OrthophotoShouData', JSON.stringify(item));

        this.$router.push({
          path: '/resourceIndex/orthographicShou',
        });
      } else if (item.type == 1) {
        console.log('模型');
        sessionStorage.setItem('ModelShouData', JSON.stringify(item));
        this.$router.push({
          path: '/resourceIndex/modelShou',
        });
      }
    },
    //预览正射
    lookOrthophotoFn(row) {
      var _this = this;
      var center = [0, 0];
      // 引入瓦片图-平面

      // 创建地图
      this.map = new AMap.Map('OrthophotoContent', {
        resizeEnable: true,
        expandZoomRange: true,
        zoom: 18, //地图显示的缩放级别
        // layers: [
        //   new AMap.TileLayer({
        //     getTileUrl: function (x, y, z) {
        //       return Api.GaoDeStreetMap() + z + "/" + x + "/" + y + ".png";
        //     },
        //     zIndex: 10,
        //   }),
        // ],
      });
      // 修改主题样式
      //   if (this.$online) {
      //     this.map.setMapStyle('amap://styles/whitesmoke');
      //   }

      let shooting_date = row.kmlmsg[0].path.split('/');

      var path = '';

      this.$Java_post('/project/task/figure/detail', {
        bf_id: row.bf_id,
      }).then((res) => {
        console.log('获取单个建图任务详情', res);
        if (res.code == 1) {
          center = res.data.location[0];
          // this.map.setCenter([center.lat,center.lng]);

          // if(row.kmlmsg[0].maptype==1){
          if (true) {
            // _this.googleLayer = new AMap.TileLayer({
            //   zIndex: 10,
            //   getTileUrl: function (x, y, z) {
            //     return (
            //       row.kmlmsg[0].path + '/' + z + '/' + x + '/' + y + '.png'
            //     );
            //   },
            // });
            // _this.googleLayer.setMap(this.map);
            // local_data
            // console.log("?????????????",JSON.parse(row.local_data)[0]);
            setTimeout(() => {
              this.map.setCenter([center.lng, center.lat]);
            }, 1000);
          } else if (row.kmlmsg[0].maptype == 2) {
            _this.GetMapDetail = function () {
              if (_this.map.getZoom() < 19) {
                _this
                  .$post(Api.mapDetail(), {
                    kml_id: row.id,
                    shooting_date: shooting_date[shooting_date.length - 1],
                    zoom: _this.map.getZoom(),
                  })
                  .then((res) => {
                    if (res.code == 1) {
                      if (res.data && res.data.length > 0) {
                        this.imageLayerList = [];
                        res.data.forEach((ele, index) => {
                          var imageLayer1 = new AMap.ImageLayer({
                            url: ele.imagePath,
                            bounds: new AMap.Bounds(
                              [ele.west, ele.south],
                              [ele.east, ele.north]
                            ),
                            zooms: _this.map.getZoom(),
                          });
                          _this.map.add(imageLayer1);
                          _this.imageLayerList.push(imageLayer1);
                        });
                        path = [res.data[0].west, res.data[0].south];
                      }
                    } else {
                      // _this.$Message.error(res.msg_customer);
                    }
                  });
              }
            };
            _this.GetMapDetail();
            setTimeout(() => {
              this.map.setCenter(path);
            }, 300);
            this.map.on('mousewheel', _this.GetMapDetail);
          }
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },
    //预览模型
    lookModelFn(row) {
      let _this = this;
      // 创建3D地图=====================
      // 使用的Cesium提供的地图和地形数据，就必须申请账号和创建AccessToken
      Cesium.Ion.defaultAccessToken = Api.CesiumToken();
      //镜头默认范围(必须在viewer实例创建前设置)
      // 定位在中国上空
      Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
        73.0,
        3.0,
        135.0,
        53.0
      );

      let viewer = new Cesium.Viewer('ModelContent', {
        animation: false, //是否创建动画小器件，左下角仪表
        baseLayerPicker: false, //是否显示图层选择器
        fullscreenButton: false, //是否显示全屏按钮
        geocoder: false, //是否显示geocoder小器件，右上角查询按钮
        homeButton: true, //是否显示Home按钮
        infoBox: false, //是否显示信息框
        sceneModePicker: false, //是否显示3D/2D选择器
        selectionIndicator: false, //是否显示选取指示器组件
        timeline: false, //是否显示时间轴
        navigationHelpButton: false, //是否显示右上角的帮助按钮
        // 加载Cesium自带地图
        // imageryProvider: new Cesium.TileMapServiceImageryProvider({
        //   url: Cesium.buildModuleUrl('Assets/Textures/NaturalEarthII'),
        // }),
      });
      // 全局注册cesium(不能挂载到data里,数据劫持会造成地图卡顿)
      // window.Viewer_figure = viewer;
      // 去掉版权信息
      viewer.cesiumWidget.creditContainer.style.display = 'none';
      viewer.scene.globe.show = false; //不显示地球，这条和地球透明度选一个就可以

      //天地图卫星影像
      if (!this.$online) {
        // 加载本地地图=======================================
        var arcgis_blue = viewer.imageryLayers.addImageryProvider(
          new Cesium.TileMapServiceImageryProvider({
            // 有的版本是用createTileMapServiceImageryProvider
            url: Api.TianDiTuMap(),
            fileExtension: 'png',
          })
        );
      }
      // 开启地形深度检测
      viewer.scene.globe.depthTestAgainstTerrain = true;

      // home定位到具体地点和高度(长沙)
      viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
        function (e) {
          e.cancel = true;
          //你要飞的位置
          viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
              _this.$Center[0],
              _this.$Center[1],
              //   112.978159,
              //   28.1608,
              50000
            ),
          });
        }
      );

      // 加载3DTileset===================================
      var tileset = viewer.scene.primitives.add(
        new Cesium.Cesium3DTileset({
          url: row.path,
          maximumScreenSpaceError: 5, //最大的屏幕空间误差
          //   maximumNumberOfLoadedTiles: 1000000000000000, //最大加载瓦片个数
        })
      );
      viewer.scene.primitives.add(tileset);
      tileset.readyPromise.then(function (tileset) {
        viewer.scene.primitives.add(tileset);
        var heightOffset = -10.0; //高度
        var boundingSphere = tileset.boundingSphere;
        var cartographic = Cesium.Cartographic.fromCartesian(
          boundingSphere.center
        );
        var surface = Cesium.Cartesian3.fromRadians(
          cartographic.longitude,
          cartographic.latitude,
          0.0
        );
        var offset = Cesium.Cartesian3.fromRadians(
          cartographic.longitude,
          cartographic.latitude,
          heightOffset
        );
        var translation = Cesium.Cartesian3.subtract(
          offset,
          surface,
          new Cesium.Cartesian3()
        );
        tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
        viewer.zoomTo(
          tileset,
          new Cesium.HeadingPitchRange(
            2.5,
            -0.5,
            tileset.boundingSphere.radius * 1
          )
        );
      });
    },
    closeLookModel() {},
  },
};
</script>

<style scoped lang="scss">
#Terra {
  width: 100%;
  height: 100%;
  padding: 20px;
  position: relative;
  background-color: #f5f5f5;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .left {
      width: 800px;
      display: flex;
      .SelectBox {
        width: 200px;
      }
      .InputBox {
        margin: 0 20px;
        width: 300px;
      }
    }
  }
  .content {
    width: 100%;
    padding: 20px 0 20px 20px;
    overflow-y: auto;
    position: absolute;
    top: 70px;
    bottom: 0;
    left: 0;
    .monthItem {
      margin-bottom: 20px;
      > h1 {
        font-size: 16px;
        font-weight: bold;
        color: #111;
        margin-bottom: 12px;
      }
      .TaskBox {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .TaskItem {
          position: relative;
          .more {
            position: absolute;
            right: 20px;
            bottom: 27px;
            .more_i {
              display: block;
              cursor: pointer;
              width: 30px;
              line-height: 34px;
              font-size: 24px;
            }
            .api {
              margin: 4px 0;
              .Divider {
                width: 100%;
                height: 1px;
                margin: 6px 0;
                background-color: #c5c8ce;
              }
              .Button {
                cursor: pointer;
              }
            }
          }
          .ItemBox {
            cursor: pointer;
            width: 234px;
            height: 234px;
            border-radius: 6px;
            overflow: hidden;
            margin-right: 20px;
            margin-bottom: 20px;
            position: relative;
            .imgBox {
              width: 234px;
              height: 181px;
              position: relative;
              overflow: hidden;
              img {
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
            .text {
              position: absolute;
              bottom: 0;
              width: 234px;
              height: 53px;
              background-color: #fff;
              padding: 10px 0 10px 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .textLeft {
                h2 {
                  font-size: 12px;
                  line-height: 14px;
                }
              }
            }
            .label {
              position: absolute;
              top: 10px;
              left: 10px;
              width: 63px;
              height: 25px;
              background: #000000;
              opacity: 0.7;
              border-radius: 4px;
              span {
                display: block;
                font-size: 12px;
                line-height: 25px;
                text-align: center;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .TerraList {
    position: absolute;
    top: 60px;
    width: 640px;
    height: 380px;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    transition: all 0.5s ease-in;
    border-radius: 0.1rem 0 0 0.1rem;
  }
  .right {
    right: 0;
  }
}
/deep/.Terra_upLoading-modal {
  .content {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      width: 136px;
      height: 62px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      transition: all 0.4s ease-in;
    }
    > div:hover {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    }
  }
}
/deep/.Look-center-modal {
  .ivu-modal-body {
    padding: 0;
  }
  .OrthophotoContent {
    width: 100%;
    height: 100%;
    /*background-color: #ee2f3f;*/
  }
  .ModelContent {
    width: 100%;
    height: 100%;
    /*background-color: #b4b4b4;*/
  }
}
</style>
